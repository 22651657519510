* {
  margin: 0;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  max-width: 100%; /* Ensure no content exceeds the viewport width */
  margin: 0rem auto;
}

body {
  background-color: rgb(65, 65, 65);
  scroll-behavior: smooth;
  overflow-x: hidden; /* Disable horizontal scrolling */
}

h1 {
  position: relative;
  display: inline-block; /* Makes width match text content */
  justify-content: center;
  align-items: center; 
  text-align: center; 
  font-family: "Jersey 10", sans-serif;
  font-weight: 550;
  font-style: normal;
  font-size: 500%;
  color: beige;
  width: 100%;
}

h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 20%; /* Match width of the text */
  transform: translateX(-90%) translateY(-50%); /* Center-align the box */
  height: 15px; /* Adjust height */
  background-color: #ff4d5a; /* Red color for the box */
  z-index: -1; /* Keep it behind the text */
}

h3 {
  display: flex;
  color: beige;
}

.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  flex-direction: row;
  flex-wrap: wrap;
}

/* -------- Cursor Follow Section */
/* -------- Cursor Follow Section */
/* -------- Cursor Follow Section */
/* -------- Cursor Follow Section */

/* .circle {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
} */

/* -------- Animation Section */
/* -------- Animation Section */
/* -------- Animation Section */
/* -------- Animation Section */

.box-area {
  position: absolute; /* Fixed to viewport */
  z-index: -1;
  height: 100vh;
  left: 0;
  top: 0;
}

.box-area li {
	position: fixed;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	background: rgba(255, 255, 255, 0.2);
	animation: animate 20s linear infinite;
	bottom: -150px;
	border-radius: 50%;
  will-change: transform, opacity; /* Optimizes rendering */
  animation-play-state: running; /* Ensures animation does not pause */
}
.box-area li:nth-child(1) {
	left: 86%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}
.box-area li:nth-child(2) {
	left: 12%;
	width: 30px;
	height: 30px;
	animation-delay: 1.5s;
	animation-duration: 10s;
}
.box-area li:nth-child(3) {
	left: 70%;
	width: 100px;
	height: 100px;
	animation-delay: 5.5s;
}
.box-area li:nth-child(4) {
	left: 42%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 15s;
}
.box-area li:nth-child(5) {
	left: 65%;
	width: 40px;
	height: 40px;
	animation-delay: 0s;
}

.box-area li:nth-child(6) {
	left: 25%;
	width: 60px;
	height: 60px;
	animation-delay: 2s;
	animation-duration: 12s;
}
.box-area li:nth-child(7) {
	left: 90%;
	width: 50px;
	height: 50px;
	animation-delay: 3s;
	animation-duration: 18s;
}

@keyframes animate {
  0% {
    transform: translateY(0) scale(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) scale(2);
    opacity: 0;
  }
}

/* ------ Navigation Bar CSS */
/* ------ Navigation Bar CSS */
/* ------ Navigation Bar CSS */

nav {
  z-index: 100;
  background-color: #343434;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links-container {
  margin-right: 3%;
}

.navbar-links-container a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: beige;
  margin-left: 2rem; 
}

.navbar-links-container a:hover {
  color: #ff4d5a;
}

.navBar-icon {
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  margin-right: 5px;
}

.navbar-menu-container {
  display: none;
}

.navbar-menu-container svg {
  cursor: pointer;
}

/* ----- Home Page CSS */
/* ----- Home Page CSS */
/* ----- Home Page CSS */


.home-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  min-height: 100vh; 
}

.home-heading-section {
  border-radius: 15px;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  text-align: center; 
}

.home-heading-section .buttons {
  display: flex;
  flex-direction: row;
}

.home-heading-section .buttons button{
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.home-heading-section h1 span,
.home-heading-section h1 {
  font-weight: 500;
  font-style: normal;
  font-size: 60px;
  color: beige;
}

.home-heading-section h2 {
  position: relative;
  font-weight: 550;
  font-style: normal;
  font-size: 50px;
  color: beige;
}

.home-heading-section h2::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0%; /* Start hidden */
  height: 100%;
  color: #d0a65a; /* New text color as highlight passes */
  -webkit-text-stroke: 0px #095b45; /* Remove outline from overlay text */
  border-right: 2px solid #d0a65a; /* Typing cursor effect */
  overflow: hidden;
  white-space: nowrap; /* Ensures text remains in one line */
  animation: highlight 7s linear infinite; /* Runs animation */
}

/* Animation to Sweep Through the Text */
@keyframes highlight {
  0% {
      width: 0%;
  }
  50% {
      width: 100%;
  }
  100% {
      width: 0%;
  }
}

.home-heading-section button {
  margin-top: 5%;
  width: 300px;
  height: 50px;
  font-size: 25px; /* Increases text size */
  background-color: transparent; /* Button background color */
  color: #ff4d5a; /* Text color */
  border: 2px solid #ff4d5a; /* Adds a border with color matching the background */
  cursor: pointer; /* Changes cursor to pointer on hover */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.home-heading-section button:hover {
  background-color: beige; /* Changes background color on hover */
  color: #ff4d5a; /* Changes text color on hover */
  border-color: #ff4d5a; /* Keeps border color consistent on hover */
}

.home-section #primary-text {
  font-size: 20px;
  color: beige;
}

.home-section p {
  margin-top: 3%;
}
/* ----- About Me Page CSS */
/* ----- About Me Page CSS */
/* ----- About Me Page CSS */

.aboutme-section-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 5rem;
}

.aboutme-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.about-me-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200px;
  color: beige;
  background-color: transparent;
}

/* .aboutme-section-top h1 {
  font-weight: 500;
  font-style: normal;
  font-size: 600%;
  color: #ff4d5a;
} */

.aboutme-section-bottom{
  display: flex;
  justify-content: center;
}


.aboutme-section-bottom p {
  color: beige;
  font-weight: 300;
  font-size: 120%;
  margin-top: 2%;
  max-width: 90% !important;
}


  /* -------- Skillset Section */
  /* -------- Skillset Section */
  /* -------- Skillset Section */
  /* -------- Skillset Section */

.skills-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.skills-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; 
  width: 75%;
}

.skills-section h2 {
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5%;
  font-size: 300%;
  color: #ff4d5a;
}

.language-logos-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  background: transparent;
  border-radius: 5px;
}

.language-logos-container p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20%;
  height: 75px;
  border-radius: 5px;
  margin: 2.5%;
  margin: 2.5%;
  color: beige;
  position: relative; /* Required for pseudo-element positioning */
  z-index: 1;
}

.language-logos-container p::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: 5px;
  background: linear-gradient(45deg, #fa9ea4, #ff626c, #fa9ea4); /* Gradient colors */
  z-index: -1; /* Send the gradient behind the element */
  -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 2px; /* Matches the original border width */
}

.skillset-icon {
  font-size: 25px;
  color: #4db6d1;
}

  /* -------- Portfolio Section */
  /* -------- Portfolio Section */
  /* -------- Portfolio Section */
  /* -------- Portfolio Section */

.portfolio-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  text-align: center; 
  min-height: 100vh; 
}

.portfolio-section Button{
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center; 
}


.portfolio-section img {
  display: block;
}


.button-container {
  margin-top: 4%;
  display: flex;
  justify-content: center;
  height: 25px; 
}

.button-container button {  
  border-radius: 15px;
  color: #ffffff; /* White */
  padding: 10px 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
}

.button-container button:hover {
  background-color: #0056b3; /* Darker Blue */
  border-color: #0056b3; /* Darker Blue */
}

  /* -------- Contact Section */
  /* -------- Contact Section */
  /* -------- Contact Section */
  /* -------- Contact Section */

.work-section-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 5rem;
}

.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.work-section-top p {
  color: beige;
  font-size: 120%;
  margin-top: 2%;
  max-width: 600px !important;
}
.work-section-top h1 {
  font-weight: 550;
  font-style: normal;
  font-size: 600%;
  color: #ff4d5a;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info {
  text-decoration: none;
  width: 290px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.work-section-info:hover {
  background-color: #008f9f; 
  transform: scale(1.05); 
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); 
}

.work-section-info h2 {
  margin: 1rem 0rem;
}

.info-boxes-img-container {
  color: #19839e;
}


/* Iphone Compatibility */
@media (max-width: 700px) {

  h1 {
    font-size: 300%;
  }

  h1::after {
    width: 40%; /* Match width of the text */
    transform: translateX(-90%) translateY(-10%); /* Center-align the box */
  }

  h3 {
    width: 95%;
  }

  .navbar-menu-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    transform: scale(2);
    color: white;
    margin-right: 5%;
  }

  .navbar-links-container{
    display: none;
  }

  /* -------- Animation Background */
  /* -------- Animation Background */
  /* -------- Animation Background */

  .box-area {
    height: 200vw;
  }

  /* -------- Home Component Section */
  /* -------- Home Component Section */
  /* -------- Home Component Section */

  .home-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    text-align: center; 
    min-height: 100vh; 
  }

  .home-heading-section h1 span,
  .home-heading-section h1 {
    font-size: 40px;
  }

  .home-heading-section h2 {
    font-weight: 550;
    font-size: 45px;
  }

  .home-heading-section .buttons {
    flex-direction: column;
  }

  .home-heading-section .buttons button{
    margin-left: 0;
    margin-right: 0;
  }

  /* ----- About Me Page CSS */
  /* ----- About Me Page CSS */
  /* ----- About Me Page CSS */

  .about-me-logo{
    font-size: 150px;
  }

  .aboutme-section-top p {
    align-items: start;
    color: beige;
    font-size: 100%;
    margin-top: 2%;
  }
  .aboutme-section-top h1 {
    font-weight: 550;
    font-size: 400%;
  }

  .aboutme-section-bottom{
    text-align: left;
  }

  /* -------- Skillset Section */
  /* -------- Skillset Section */
  /* -------- Skillset Section */

  .skills-container{
    min-height: 50vh;
  }

  #personal-skills-title {
    font-size: 300%;
    font-weight: 550;
  }

  .skills-section {
    width: 50%;
  }

  .skills-section h2 {
    font-size: 200%;
  }

  .language-logos-container {
    width: 100%;
  }

  .language-logos-container p {
    margin-top: 5%;
    font-size: 0px;
  }

  /* -------- Portfolio Section */
  /* -------- Portfolio Section */
  /* -------- Portfolio Section */
  /* -------- Portfolio Section */

  .portfolio-section {
    max-width: 90vw; 
    min-height: 75vh;
  }


  /* -------- Contact page Section */
  /* -------- Contact page Section */
  /* -------- Contact page Section */
  /* -------- Contact page Section */

  .work-section-wrapper {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .button-container {
    transform: scale(0.8);
    margin-top: 10%;
    margin-bottom: 0%;
  }

  .button-container button {
    font-size: 100%;
  }

  .work-section-top p {
    font-size: 100%;
    display: flex;
    width: 90%;
  }
  
  .work-section-top h1 {
    font-size: 240%;
  }

  .work-section-bottom {
    margin-top: 0rem;
    margin-bottom: 15rem;
    transform: scale(0.9);
    flex-wrap: nowrap;
    flex-direction: column;
  }


}

/* Ipad Compatibility */
@media (max-width: 1100px){
  /* -------- Skillset Section */
  /* -------- Skillset Section */
  /* -------- Skillset Section */

  .skills-section {
    width: 95%;
  }
}